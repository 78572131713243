export const GET_SIZES = "GET_SIZES"
export const GET_SIZES_SUCCESS = "GET_SIZES_SUCCESS"
export const GET_SIZES_FAILED = "GET_SIZES_FAILED"

export const GET_SIZE = "GET_SIZE"
export const GET_SIZE_SUCCESS = "GET_SIZE_SUCCESS"
export const GET_SIZE_FAILED = "GET_SIZE_FAILED"

export const REGISTER_SIZE = "REGISTER_SIZE"
export const REGISTER_SIZE_SUCCESS = "REGISTER_SIZE_SUCCESS"
export const REGISTER_SIZE_FAILED = "REGISTER_SIZE_FAILED"

export const UPDATE_SIZE = "UPDATE_SIZE"
export const UPDATE_SIZE_SUCCESS = "UPDATE_SIZE_SUCCESS"
export const UPDATE_SIZE_FAILED = "UPDATE_SIZE_FAILED"
