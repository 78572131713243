export const RESET_BILL = "RESET_BILL"

export const QUERY_BILLS = "QUERY_BILLS"
export const QUERY_BILLS_SUCCESS = "QUERY_BILLS_SUCCESS"
export const QUERY_BILLS_FAILED = "QUERY_BILLS_FAILED"

export const GET_BILLS = "GET_BILLS"
export const GET_BILLS_SUCCESS = "GET_BILLS_SUCCESS"
export const GET_BILLS_FAILED = "GET_BILLS_FAILED"

export const GET_BILL = "GET_BILL"
export const GET_BILL_SUCCESS = "GET_BILL_SUCCESS"
export const GET_BILL_FAILED = "GET_BILL_FAILED"

export const REGISTER_BILL = "REGISTER_BILL"
export const REGISTER_BILL_SUCCESS = "REGISTER_BILL_SUCCESS"
export const REGISTER_BILL_FAILED = "REGISTER_BILL_FAILED"

export const UPDATE_BILL = "UPDATE_BILL"
export const UPDATE_BILL_SUCCESS = "UPDATE_BILL_SUCCESS"
export const UPDATE_BILL_FAILED = "UPDATE_BILL_FAILED"

export const DELETE_BILL = "DELETE_BILL"
export const DELETE_BILL_SUCCESS = "DELETE_BILL_SUCCESS"
export const DELETE_BILL_FAILED = "DELETE_BILL_FAILED"

export const CONFIRM_BILL = "CONFIRM_BILL";
export const CONFIRM_BILL_SUCCESS = "CONFIRM_BILL_SUCCESS"
export const CONFIRM_BILL_FAILED = "CONFIRM_BILL_FAILED"

export const ADD_ORDER_BILL = "ADD_ORDER_BILL"
export const ADD_ORDER_BILL_SUCCESS = "ADD_ORDER_BILL_SUCCESS"
export const ADD_ORDER_BILL_FAILED = "ADD_ORDER_BILL_FAILED"

export const BILL_LIST_REFRESH = "BILL_LIST_REFRESH"


export const GENERATE_CREDIT_NOTE = "GENERATE_CREDIT_NOTE"
export const GENERATE_CREDIT_NOTE_SUCCESS = "GENERATE_CREDIT_NOTE_SUCCESS"
export const GENERATE_CREDIT_NOTE_FAILED = "GENERATE_CREDIT_NOTE_FAILED"

export const SEND_INVOICE = "SEND_INVOICE"
export const SEND_INVOICE_SUCCESS = "SEND_INVOICE_SUCCESS"
export const SEND_INVOICE_FAILED = "SEND_INVOICE_FAILED"

export const GENERATE_REPORT_RESTART = "GENERATE_REPORT_RESTART"
export const GENERATE_REPORT_REQUEST = "GENERATE_REPORT_REQUEST"
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS"
export const GENERATE_REPORT_FAILED = "GENERATE_REPORT_FAILED"
