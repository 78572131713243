export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED"

export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAILED = "GET_CATEGORY_FAILED"

export const REGISTER_CATEGORY = "REGISTER_CATEGORY"
export const REGISTER_CATEGORY_SUCCESS = "REGISTER_CATEGORY_SUCCESS"
export const REGISTER_CATEGORY_FAILED = "REGISTER_CATEGORY_FAILED"

export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAILED = "UPDATE_CATEGORY_FAILED"

export const CATALOG_PRINT_BATCH_REQUEST = "CATALOG_PRINT_BATCH_REQUEST"
export const CATALOG_PRINT_BATCH_REQUEST_SUCCESS = "CATALOG_PRINT_BATCH_REQUEST_SUCCESS"
export const CATALOG_PRINT_BATCH_REQUEST_FAILED = "CATALOG_PRINT_BATCH_REQUEST_FAILED"
export const CATALOG_DO_BATCH_REQUEST = "CATALOG_DO_BATCH_REQUEST"
export const CATALOG_RESET_BATCH_REQUEST = "CATALOG_RESET_BATCH_REQUEST"

export const REFRESH_CATEGORIES = "REFRESH_CATEGORIES";

export const GET_PIECES_UNPUBLISHED = "GET_PIECES_UNPUBLISHED"
export const GET_PIECES_UNPUBLISHED_SUCCESS = "GET_PIECES_UNPUBLISHED_SUCCESS"
export const GET_PIECES_UNPUBLISHED_FAILED = "GET_PIECES_UNPUBLISHED_FAILED"


export const RESET_CATEGORY = "RESET_CATEGORY";
