export const REGISTER_STATE = "REGISTER_STATE"
export const REGISTER_STATE_SUCCESS = "REGISTER_STATE_SUCCESS"
export const REGISTER_STATE_FAILED = "REGISTER_STATE_FAILED"

export const GET_STATE = "GET_STATE"
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS"
export const GET_STATE_FAILED = "GET_STATE_FAILED"

export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_FAILED = "GET_STATES_FAILED"

export const UPDATE_STATE = "UPDATE_STATE"
export const UPDATE_STATE_SUCCESS = "UPDATE_STATE_SUCCESS"
export const UPDATE_STATE_FAILED = "UPDATE_STATE_FAILED"

export const DELETE_STATE = "DELETE_STATE"
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS"
export const DELETE_STATE_FAILED = "DELETE_STATE_FAILED"


export const REGISTER_MUNICIPALITY = "REGISTER_MUNICIPALITY"
export const REGISTER_MUNICIPALITY_SUCCESS = "REGISTER_MUNICIPALITY_SUCCESS"
export const REGISTER_MUNICIPALITY_FAILED = "REGISTER_MUNICIPALITY_FAILED"

export const GET_MUNICIPALITY = "GET_MUNICIPALITY"
export const GET_MUNICIPALITY_SUCCESS = "GET_MUNICIPALITY_SUCCESS"
export const GET_MUNICIPALITY_FAILED = "GET_MUNICIPALITY_FAILED"

export const GET_MUNICIPALITIES = "GET_MUNICIPALITIES"
export const GET_MUNICIPALITIES_SUCCESS = "GET_MUNICIPALITIES_SUCCESS"
export const GET_MUNICIPALITIES_FAILED = "GET_MUNICIPALITIES_FAILED"

export const UPDATE_MUNICIPALITY = "UPDATE_MUNICIPALITY"
export const UPDATE_MUNICIPALITY_SUCCESS = "UPDATE_MUNICIPALITY_SUCCESS"
export const UPDATE_MUNICIPALITY_FAILED = "UPDATE_MUNICIPALITY_FAILED"

export const DELETE_MUNICIPALITY = "DELETE_MUNICIPALITY"
export const DELETE_MUNICIPALITY_SUCCESS = "DELETE_MUNICIPALITY_SUCCESS"
export const DELETE_MUNICIPALITY_FAILED = "DELETE_MUNICIPALITY_FAILED"

export const RESET_LOCATION = "RESET_LOCATION";
