export const GET_FIELD_OPTIONS = "GET_FIELD_OPTIONS"
export const GET_FIELD_OPTIONS_SUCCESS = "GET_FIELD_OPTIONS_SUCCESS"
export const GET_FIELD_OPTIONS_FAILED = "GET_FIELD_OPTIONS_FAILED"

export const GET_FIELD_OPTION = "GET_FIELD_OPTION"
export const GET_FIELD_OPTION_SUCCESS = "GET_FIELD_OPTION_SUCCESS"
export const GET_FIELD_OPTION_FAILED = "GET_FIELD_OPTION_FAILED"

export const REGISTER_FIELD_OPTION = "REGISTER_FIELD_OPTION"
export const REGISTER_FIELD_OPTION_SUCCESS = "REGISTER_FIELD_OPTION_SUCCESS"
export const REGISTER_FIELD_OPTION_FAILED = "REGISTER_FIELD_OPTION_FAILED"

export const UPDATE_FIELD_OPTION = "UPDATE_FIELD_OPTION"
export const UPDATE_FIELD_OPTION_SUCCESS = "UPDATE_FIELD_OPTION_SUCCESS"
export const UPDATE_FIELD_OPTION_FAILED = "UPDATE_FIELD_OPTION_FAILED"

export const DELETE_FIELD_OPTION = "DELETE_FIELD_OPTION"
export const DELETE_FIELD_OPTION_SUCCESS = "DELETE_FIELD_OPTION_SUCCESS"
export const DELETE_FIELD_OPTION_FAILED = "DELETE_FIELD_OPTION_FAILED"
