export const GET_PAYMENTS = "GET_PAYMENTS"
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"
export const GET_PAYMENTS_FAILED = "GET_PAYMENTS_FAILED"

export const GET_PAYMENT = "GET_PAYMENT"
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS"
export const GET_PAYMENT_FAILED = "GET_PAYMENT_FAILED"

export const DELETE_PAYMENT = "DELETE_PAYMENT"
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"
export const DELETE_PAYMENT_FAILED = "DELETE_PAYMENT_FAILED"

export const REGISTER_PAYMENT = "REGISTER_PAYMENT"
export const REGISTER_PAYMENT_SUCCESS = "REGISTER_PAYMENT_SUCCESS"
export const REGISTER_PAYMENT_FAILED = "REGISTER_PAYMENT_FAILED"

export const UPDATE_PAYMENT = "UPDATE_PAYMENT"
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_FAILED = "UPDATE_PAYMENT_FAILED"

export const APPLY_PAYMENT = "APPLY_PAYMENT"
export const APPLY_PAYMENT_SUCCESS = "APPLY_PAYMENT_SUCCESS"
export const APPLY_PAYMENT_FAILED = "APPLY_PAYMENT_FAILED"
