export const GET_COMMENTS = "GET_COMMENTS"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_FAILED = "GET_COMMENTS_FAILED"

export const GET_COMMENT = "GET_COMMENT"
export const GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS"
export const GET_COMMENT_FAILED = "GET_COMMENT_FAILED"

export const REGISTER_COMMENT = "REGISTER_COMMENT"
export const REGISTER_COMMENT_SUCCESS = "REGISTER_COMMENT_SUCCESS"
export const REGISTER_COMMENT_FAILED = "REGISTER_COMMENT_FAILED"

export const UPDATE_COMMENT = "UPDATE_COMMENT"
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS"
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED"

export const DELETE_COMMENT = "DELETE_COMMENT"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED"
