export const GET_TEMPLATES = "GET_TEMPLATES"
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS"
export const GET_TEMPLATES_FAILED = "GET_TEMPLATES_FAILED"

export const GET_TEMPLATE = "GET_TEMPLATE"
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS"
export const GET_TEMPLATE_FAILED = "GET_TEMPLATE_FAILED"

export const REGISTER_TEMPLATE = "REGISTER_TEMPLATE"
export const REGISTER_TEMPLATE_SUCCESS = "REGISTER_TEMPLATE_SUCCESS"
export const REGISTER_TEMPLATE_FAILED = "REGISTER_TEMPLATE_FAILED"

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS"
export const UPDATE_TEMPLATE_FAILED = "UPDATE_TEMPLATE_FAILED"

export const RESET_TEMPLATE = "RESET_TEMPLATE";

export const GET_TEMPLATE_CATALOG = "GET_TEMPLATE_CATALOG"
export const GET_TEMPLATE_CATALOG_SUCCESS = "GET_TEMPLATE_CATALOG_SUCCESS"
export const GET_TEMPLATE_CATALOG_FAILED = "GET_TEMPLATE_CATALOG_FAILED"

