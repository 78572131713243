export const RESET_CUSTOMERS = "RESET_CUSTOMERS"

export const GET_CUSTOMER = "GET_CUSTOMER"
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS"
export const GET_CUSTOMER_FAILED = "GET_CUSTOMER_FAILED"

export const QUERY_CUSTOMERS = "QUERY_CUSTOMERS"
export const QUERY_CUSTOMERS_FAILED = "QUERY_CUSTOMERS_FAILED"
export const QUERY_CUSTOMERS_SUCCESS = "QUERY_CUSTOMERS_SUCCESS"

export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAILED = "GET_CUSTOMERS_FAILED"

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILED = "UPDATE_CUSTOMER_FAILED";

export const REGISTER_CUSTOMER = "REGISTER_CUSTOMER"
export const REGISTER_CUSTOMER_SUCCESS = "REGISTER_CUSTOMER_SUCCESS"
export const REGISTER_CUSTOMER_FAILED = "REGISTER_CUSTOMER_FAILED"

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILED = "DELETE_CUSTOMER_FAILED";

export const GET_CUSTOMER_REGISTEREDS = "GET_CUSTOMER_REGISTEREDS";
export const GET_CUSTOMER_REGISTEREDS_SUCCESS = "GET_CUSTOMER_REGISTEREDS_SUCCESS";
export const GET_CUSTOMER_REGISTEREDS_FAILED = "GET_CUSTOMER_REGISTEREDS_FAILED";

export const REGISTER_VCARD = "REGISTER_VCARD"
export const REGISTER_VCARD_SUCCESS = "REGISTER_VCARD_SUCCESS"
export const REGISTER_VCARD_FAILED = "REGISTER_VCARD_FAILED"
