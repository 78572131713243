import React from "react"
import {Container} from "reactstrap"

const PageNotFount = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h1>404</h1>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default PageNotFount;
