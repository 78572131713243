import React from "react"

const NoAccess = props => {
    return (
        <div className="text-center text-muted">
            <h1>Acceso denegado</h1>
        </div>
    )
}
export default NoAccess
