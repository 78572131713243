export const GET_DELIVERY_LOCALITIES = "GET_DELIVERY_LOCALITIES"
export const GET_DELIVERY_LOCALITIES_SUCCESS = "GET_DELIVERY_LOCALITIES_SUCCESS"
export const GET_DELIVERY_LOCALITIES_FAILED = "GET_DELIVERY_LOCALITIES_FAILED"

export const GET_DELIVERY_LOCALITY = "GET_DELIVERY_LOCALITY"
export const GET_DELIVERY_LOCALITY_SUCCESS = "GET_DELIVERY_LOCALITY_SUCCESS"
export const GET_DELIVERY_LOCALITY_FAILED = "GET_DELIVERY_LOCALITY_FAILED"

export const REGISTER_DELIVERY_LOCALITY = "REGISTER_DELIVERY_LOCALITY"
export const REGISTER_DELIVERY_LOCALITY_SUCCESS = "REGISTER_DELIVERY_LOCALITY_SUCCESS"
export const REGISTER_DELIVERY_LOCALITY_FAILED = "REGISTER_DELIVERY_LOCALITY_FAILED"

export const UPDATE_DELIVERY_LOCALITY = "UPDATE_DELIVERY_LOCALITY"
export const UPDATE_DELIVERY_LOCALITY_SUCCESS = "UPDATE_DELIVERY_LOCALITY_SUCCESS"
export const UPDATE_DELIVERY_LOCALITY_FAILED = "UPDATE_DELIVERY_LOCALITY_FAILED"
