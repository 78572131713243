export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAILED = "GET_USERS_FAILED"

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILED = "GET_USER_FAILED"

export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED"

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD"
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED"

export const RESET_USER = "RESET_USER";

export const COUNTER_REGISTER_ORDERS = "COUNTER_REGISTER_ORDERS";

export const COUNT_USERS = "COUNT_USERS"
export const COUNT_USERS_SUCCESS = "COUNT_USERS_SUCCESS"
export const COUNT_USERS_FAILED = "COUNT_USERS_FAILED"

