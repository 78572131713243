export const RESET_PRODUCT_IMAGES = "RESET_PRODUCT_IMAGES"
export const GET_PRODUCT_IMAGES = "GET_PRODUCT_IMAGES"
export const GET_PRODUCT_IMAGES_SUCCESS = "GET_PRODUCT_IMAGES_SUCCESS"
export const GET_PRODUCT_IMAGES_FAILED = "GET_PRODUCT_IMAGES_FAILED"

export const GET_PRODUCT_IMAGE = "GET_PRODUCT_IMAGE"
export const GET_PRODUCT_IMAGE_SUCCESS = "GET_PRODUCT_IMAGE_SUCCESS"
export const GET_PRODUCT_IMAGE_FAILED = "GET_PRODUCT_IMAGE_FAILED"

export const REGISTER_PRODUCT_IMAGE = "REGISTER_PRODUCT_IMAGE"
export const REGISTER_PRODUCT_IMAGE_SUCCESS = "REGISTER_PRODUCT_IMAGE_SUCCESS"
export const REGISTER_PRODUCT_IMAGE_FAILED = "REGISTER_PRODUCT_IMAGE_FAILED"

export const UPDATE_PRODUCT_IMAGE = "UPDATE_PRODUCT_IMAGE"
export const UPDATE_PRODUCT_IMAGE_SUCCESS = "UPDATE_PRODUCT_IMAGE_SUCCESS"
export const UPDATE_PRODUCT_IMAGE_FAILED = "UPDATE_PRODUCT_IMAGE_FAILED"

export const DELETE_PRODUCT_IMAGE = "DELETE_PRODUCT_IMAGE"

export const REFRESH_PRODUCT = "REFRESH_PRODUCT"
